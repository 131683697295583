import React, { ComponentProps, useState } from 'react'

import { FormText, Radio, RadioGroup } from '@te-digi/styleguide'

const RadioGroupExample1 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof RadioGroup>['value']>('1')

  return (
    <>
      <RadioGroup
        help="Help"
        label="Valitse sopiva vaihtoehto"
        name="radio-group-example-1"
        onChange={e => setValue(e)}
        required
        subLabel="Valintaan liittyvä lisäohjeitus"
        value={value}
      >
        <Radio value="1">Vaihtoehto 1</Radio>
        <Radio
          help="In vino veritas"
          helpButtonLabel="Alea yacta est"
          value="2"
        >
          Vaihtoehto 2 lisäohjeistuksella
        </Radio>
        <Radio
          disabled
          value="3"
        >
          Disabloitu vaihtoehto 3
        </Radio>
      </RadioGroup>
      <FormText>Valittu: {value}</FormText>
    </>
  )
}

export { RadioGroupExample1 }
